import React from 'react';
import RawHTML from '@components/Common/RawHTML';

export const ChatSectionWithSubSection = ({ items }) => {
  return (
    <div className="-mx-2 flex flex-col gap-3 p-2 md:-mx-4 md:gap-4 md:p-2">
      {items.map((section, index) => (
        <div key={index} className="flex flex-col gap-3">
          <div className="rounded-lg bg-transparent p-3">
            <RawHTML className="text-base font-semibold text-black">
              {section.title}
            </RawHTML>
            {section.description && (
              <p className="mt-1 text-sm text-black">{section.description}</p>
            )}
          </div>
          {section.items && (
            <div className="flex flex-col gap-3">
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="rounded-lg bg-transparent p-3">
                  <RawHTML className="text-sm font-medium text-black">
                    {item.title}
                  </RawHTML>
                  <p className="mt-1 text-sm text-black/80">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
