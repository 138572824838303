import React from 'react';
import RawHTML from '@components/Common/RawHTML';
import * as ProjectImages from './ProjectImages';

export const ProjectCard = ({ project }) => {
  const ImageComponent = project.imageType
    ? ProjectImages[`${project.imageType}Image`]
    : null;

  return (
    <div className="overflow-hidden rounded-xl border border-[#FFF5DB]/20 bg-transparent">
      <div className="relative flex h-48 items-center justify-center overflow-hidden rounded-t-lg bg-transparent">
        {ImageComponent && (
          <div className="flex h-full w-full items-center justify-center p-4">
            <ImageComponent />
          </div>
        )}
      </div>
      <div className="p-4">
        <h3 className="text-base font-medium text-black">
          <RawHTML>{project.title}</RawHTML>
        </h3>
        <p className="mt-2 text-sm text-black/80">
          <RawHTML>{project.content}</RawHTML>
        </p>
      </div>
    </div>
  );
};
