import { v4 as uuidv4 } from 'uuid';

const CONVERSATION_KEY = 'chatbot_conversation';

export const createNewConversation = () => {
  const conversation = {
    id: uuidv4(),
    messages: [],
    createdAt: new Date().toISOString(),
    userQueryCount: 0,
  };

  sessionStorage.setItem(CONVERSATION_KEY, JSON.stringify(conversation));
  return conversation;
};

export const getCurrentConversation = () => {
  try {
    const conversationStr = sessionStorage.getItem(CONVERSATION_KEY);
    return conversationStr ? JSON.parse(conversationStr) : null;
  } catch (error) {
    console.error('Error getting conversation:', error);
    return null;
  }
};

export const updateConversation = (messages, isUserQuery = false) => {
  try {
    const conversation = getCurrentConversation();
    if (conversation) {
      conversation.messages = messages;
      if (isUserQuery) {
        conversation.userQueryCount = (conversation.userQueryCount || 0) + 1;
      }
      sessionStorage.setItem(CONVERSATION_KEY, JSON.stringify(conversation));
    }
  } catch (error) {
    console.error('Error updating conversation:', error);
  }
};

export const getQueryCount = () => {
  const conversation = getCurrentConversation();
  return conversation?.userQueryCount || 0;
};
