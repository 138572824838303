import React from 'react';
import { X, RotateCcw } from 'lucide-react';
import { Tooltip } from './Tooltip';

export const ChatControls = ({ onClose, onReset }) => {
  return (
    <div className="flex items-center gap-2 text-white ">
      <button
        onClick={onReset}
        className="flex items-center gap-2 rounded-full  px-4 py-2 transition-all duration-200 "
        aria-label="Restart chat"
      >
        <span className="text-sm font-medium hover:bg-transparent">
          Restart Chat
        </span>
        <RotateCcw size={16} />
      </button>
      <Tooltip content="Close chat">
        <button
          onClick={onClose}
          className="rounded-full p-2 text-white transition-all duration-200"
          aria-label="Close chat"
        >
          <X size={20} />
        </button>
      </Tooltip>
    </div>
  );
};
