export const fullStackCurriculumMessages = [
  {
    message:
      'Here is the curriculum of Full Stack Development that Crio is offering.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Full Stack Development Curriculum',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'grid',
    payload: [
      {
        title: 'Programming and JavaScript Foundations',
        content:
          'The program starts with Programming Foundations, where learners are introduced to core programming concepts like variables, functions, loops, and problem-solving. This is followed by JavaScript Language Foundations, which focuses on JavaScript syntax, data types, objects, arrays, and ES6 features, providing a solid base for further learning.',
        skills: [
          'Programming concepts',
          'Problem-solving',
          'Algorithms',
          'JavaScript syntax',
          'ES6 features',
        ],
      },
      {
        title: 'Front-End Development Foundations',
        content:
          'The front-end track starts with foundational web technologies: HTML, CSS, and JavaScript. Learners work on projects like XCruise, building a responsive landing page, and QTripStatic, creating a static travel website using Bootstrap. The curriculum then advances to React, where learners build interactive, dynamic applications. Through projects like QKart Frontend, they explore React concepts such as state management, hooks, and routing.',
        skills: [
          'HTML',
          'CSS',
          'JavaScript',
          'Bootstrap',
          'React',
          'State management',
          'React Hooks',
        ],
      },
      {
        title: 'Advanced Front-End Development',
        content:
          'The learners use Next.js and Tailwind CSS to develop full-stack applications and optimize performance with server-side rendering and static site generation. In the QTify project, students build a music streaming platform, integrating third-party APIs and using advanced React features, while QKart Frontend focuses on building a rich e-commerce experience with React, Material UI, and custom styling.',
        skills: [
          'Next.js',
          'Tailwind CSS',
          'Server-side rendering',
          'Static site generation',
          'React',
          'Material UI',
          'Third-party APIs',
        ],
      },
      {
        title: 'Data Structures & Algorithms (DSA)',
        content:
          'The learners dive into Data Structures and Algorithms (DSA). They begin with basic problem-solving techniques and recursion, followed by more advanced topics such as Stacks, Queues, Linked Lists, and Dynamic Programming. This section builds critical problem-solving skills and prepares learners for technical interviews.',
        skills: [
          'Recursion',
          'Stacks',
          'Queues',
          'Linked Lists',
          'Dynamic Programming',
        ],
      },
      {
        title: 'Back-End Development',
        content:
          'The back-end portion introduces Node.js and Express.js for building scalable RESTful APIs. Students apply these technologies in projects like QKart Backend, where they implement authentication and connect to databases like MongoDB. The program also covers advanced back-end concepts such as Role-Based Access Control (RBAC), SQL, and Microservices, with the ExitEase project allowing students to build a full-stack application for managing employee exits.',
        skills: [
          'Node.js',
          'Express.js',
          'RESTful APIs',
          'MongoDB',
          'JWT',
          'Passport',
          'Role-Based Access Control',
          'SQL',
          'Microservices',
        ],
      },
      {
        title: 'System Design & Advanced Concepts',
        content:
          'The final section covers system design and full-stack architecture, with a focus on Microservices, Docker, Kafka, and CI/CD. Students learn how to design and deploy scalable, high-performance applications using modern development tools and practices.',
        skills: ['Microservices', 'Docker', 'Kafka', 'CI/CD'],
      },
    ],
  },
  {
    message: 'Industry-grade hands-on Projects',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'carousel',
    payload: [
      {
        title: 'XCruise',
        content: 'Build a responsive landing page',
        imageType: 'XCruise',
      },
      {
        title: 'QTripStatic & QTripDynamic',
        content: 'Create a travel booking platform',
        imageType: 'QTrip',
      },
      {
        title: 'XBoard',
        content: 'Develop a news feed application',
        imageType: 'XBoard',
      },
      {
        title: 'QKart',
        content: 'Build an e-commerce platform',
        imageType: 'QKart',
      },
      {
        title: 'QTify',
        content: 'Create a music streaming platform',
        imageType: 'QTify',
      },
      {
        title: 'ExitEase',
        content: 'Develop an employee exit management system',
        imageType: 'ExitEase',
      },
    ],
  },
];
