import ChatBotIcon from '@components/ChatBotIcon';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import React, { useContext, useEffect, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import chatIcon from '../../../src/images/ChatIcon.webp';
import backendDev from '../../../src/images/Backend-Developer.png';
import steps from '../../misc/chatbot-steps';
import stepsi18n from '../../misc/i18n/chatbot-steps';
import countries from '../../../utils/i18n-countries';
import loadable from '@loadable/component';
import * as styles from './ChatBotComponent.module.scss';
import Loader from '@components/Loader/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_CHATBOT_CALLBACK } from '@src/constants/LeadGenerationConstants/index';
import { useCustomToast } from '@components/extra/useCustomToast';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import { StaticImage } from 'gatsby-plugin-image';
import ExternalChatbot from '@components/ExternalChatBot';
import { playNotificationSound } from '@components/ExternalChatBot/utils/soundUtils';

const ChatBot = loadable(() => import('react-simple-chatbot'), {
  fallback: <Loader></Loader>,
});

export default function ChatBotComponentV2(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [fabOpen, setFabOpen] = useState(true);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [firstTimeOpen, setfirstTimeOpen] = useState(false);
  const [showTooltips, setShowTooltips] = useState(false);

  const location = useLocation();
  const showCallbackModal = location.search.includes('openCallback=true');

  const [hasTrackedOpen, setHasTrackedOpen] = useState(false);

  useEffect(() => {
    window.openChatBot = () => {
      dispatch({
        type: 'SET_CHATBOT_OPENED',
        payload: true,
      });
      setChatbotOpen(true);
    };

    if (showCallbackModal) {
      setFabOpen(true);
    }

    const tooltipTimer = setTimeout(() => {
      // Play notification sound when tooltip opens
      if (!showTooltips) playNotificationSound();
      setShowTooltips(true);
    }, 6000);

    return () => clearTimeout(tooltipTimer);
  }, []);

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        Thanks! We will give you a call or email you between 9 AM to 9 PM
      </p>
    </div>,
  );

  function onSubmit(email, phone) {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CALL_ME_POPUP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED,
      email,
    );

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    if (Cookies.get('isPhoneVerified') === 'true') triggerSuccessToast();
  }

  return (
    <div
      className={`shadow-2xl ${
        state.userIsApplying && 'hidden'
      } fixed z-[1020]`}
      style={{
        display: 'block',
      }}
    >
      {chatbotOpen ? (
        <ChatBot
          steps={countries.includes(state.country) ? stepsi18n : steps}
          bubbleOptionStyle={{
            background: '#FCB848',
            boxShadow:
              '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
            color: '#08322D',
            cursor: 'pointer',
            fontWeight: 'normal',
          }}
          botAvatar={chatIcon}
          avatarStyle={{ marginRight: '7px' }}
          bubbleStyle={{ maxWidth: '70%' }}
          userAvatar={backendDev}
          floatingIcon={<ChatBotIcon isBotFirstInteration={firstTimeOpen} />}
          opened={true}
          enableMobileAutoFocus={false}
          toggleFloating={({ opened }) => {
            if (opened) {
              GTM.track(gtmEvents.CHATBOT_OPENED);
              GTM.leadSquaredEvent(
                ELeadSquaredActivityCode.CHAT_WITH_ME_CLICKED,
                state.email,
              );
            }
            setChatbotOpen(opened);
            if (opened && !firstTimeOpen) {
              setfirstTimeOpen(true);
            }
          }}
        />
      ) : (
        ''
      )}

      <div
        onClick={() => {
          GTM.track(gtmEvents.EXTERNAL_PLATFORM_EVENT, {
            type: 'EXTERNAL_FAB_CLICK',
            location: 'REQUEST_CALLBACK',
          });
        }}
        className={styles.fab}
      >
        {fabOpen && (
          <>
            {/* Callback Button with Speech Bubble */}
            <div
              className={`fixed right-4  hidden flex-col items-end transition-all duration-300 md:right-12 ${
                //Change hidden to flex to make the callback button visible
                showTooltips
                  ? 'bottom-[10rem] md:bottom-[13.5rem]'
                  : 'bottom-[7rem] md:bottom-[10rem]'
              }`}
            >
              {showTooltips && (
                <div className="relative mb-2 -mr-2 h-auto w-[100px] rounded-lg bg-white p-2 shadow-lg after:absolute after:left-1/2 after:top-[100%] after:h-0 after:w-0 after:-translate-x-1/2 after:border-l-[8px] after:border-r-[8px] after:border-t-[8px] after:border-l-transparent after:border-r-transparent after:border-t-white md:-mr-4 md:w-[121px]">
                  <p className="text-[10px] leading-snug text-gray-800 md:text-[12px]">
                    Request callback?
                  </p>
                </div>
              )}
              <LeadGenerationButton
                buttonComponent={
                  <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full p-0 shadow-2xl transition-transform duration-200 hover:scale-105 md:h-[80px] md:w-[80px]">
                    <StaticImage
                      src="../../images/Chatbot/beaverLogo.png"
                      alt="Request Callback"
                      className="mt-2 h-full w-full rounded-full"
                      placeholder="blurred"
                    />
                  </div>
                }
                isOnePageForm
                shouldOpenLeadForm={showCallbackModal}
                formHeading="Request Callback"
                pageOneButtonText="Call me back"
                buttonLocation={LEAD_GEN_CHATBOT_CALLBACK}
                onSubmitPageOne={onSubmit}
                clickEvent={() => {
                  GTM.track(gtmEvents.CALLME_OPENED);
                  GTM.leadSquaredEvent(
                    ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED,
                    state.email,
                  );
                }}
              />
            </div>

            {/* Chat Button */}
            <div
              onClick={(e) => {
                e.stopPropagation();

                if (!hasTrackedOpen) {
                  GTM.track(gtmEvents.EXTERNAL_PLATFORM_EVENT, {
                    type: 'EXTERNAL_FAB_CLICK',
                    location: 'EXTERNAL_CHATBOT_OPEN',
                  });
                  GTM.track(gtmEvents.CHATBOT_OPENED);
                  GTM.leadSquaredEvent(
                    ELeadSquaredActivityCode.CHAT_WITH_ME_CLICKED,
                    state.email,
                  );
                  setHasTrackedOpen(true);
                }
              }}
              className="fixed bottom-4 right-4 md:bottom-12 md:right-12"
            >
              <ExternalChatbot
                setHasTrackedOpen={setHasTrackedOpen}
                showTooltip={showTooltips}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
