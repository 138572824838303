export const backendCurriculumMessages = [
  {
    message:
      'Here is the curriculum of Backend Development that Crio is offering.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Backend Development Curriculum',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'grid',
    payload: [
      {
        title: 'Programming Foundations & Core Java',
        content:
          'The curriculum begins with Programming Concepts, covering core programming constructs like variables, functions, loops, and problem-solving. Learners then progress to Java Foundations, where they practice Java syntax, data structures (arrays, collections), and debugging techniques. In Object-Oriented Programming (OOP), learners deepen their understanding of OOP principles such as inheritance, abstraction, and exception handling, while gaining familiarity with Git and Linux tools.',
      },
      {
        title: 'Advanced Java, Low-Level Design, and Design Patterns',
        content:
          'In Core Java, learners delve into advanced topics such as JSON handling, REST APIs, and unit testing. Projects like QMoney and XNews allow learners to apply Java principles to real-world applications. The Low-Level Design module introduces clean code practices, SOLID design principles, and design patterns like Factory, Singleton, and Builder. Learners apply these principles in projects like QContest (a HackerRank-like app) and Xlido (a Slido-like app).',
      },
      {
        title: 'SQL, Database Design, and Multithreading',
        content:
          'Learners master SQL fundamentals including database normalization, complex queries, joins, subqueries, and transactions. They also explore JDBC integration and multithreading to optimize application performance. Through projects like XPoll, learners apply these concepts to design efficient database schemas and build performant backend systems.',
      },
      {
        title: 'Backend Engineering with Spring Boot',
        content:
          'In Backend Engineering - I, learners focus on Spring Boot to build scalable backend systems. They work on a Swiggy-like app, QEats, developing REST APIs, integrating MongoDB, and securing the APIs with JWT and Spring Security. This section also covers microservices architecture, API documentation with Swagger, and testing with Postman, preparing learners to build production-ready backend systems.',
      },
      {
        title: 'Data Structures, Algorithms, and Problem Solving',
        content:
          'This section introduces learners to key Data Structures and Algorithms (DSA) concepts, including LinkedLists, Trees, recursion, and time-space complexity. Learners develop essential problem-solving skills through practice and apply these skills to solve algorithmic challenges. This module prepares learners for technical interviews and builds the foundation for effective software development.',
      },
      {
        title: 'Advanced Backend Topics, Deployment, and Interview Preparation',
        content:
          'This final section combines Advanced Backend topics like Hibernate, web application architecture, and AWS basics with deployment strategies. Learners also gain hands-on experience with logging and monitoring for production systems. The Interview Preparation module prepares learners for technical interviews with mock interviews, timed DSA assessments, and the Top 100 Interview Questions, ensuring they are well-prepared to enter the job market.',
      },
    ],
  },
  {
    message: 'Industry-grade hands-on Projects',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'carousel',
    payload: [
      {
        title: 'QMoney',
        content: 'A visual stock portfolio analyzer.',
        imageType: 'QMoney',
      },
      {
        title: 'XNews',
        content:
          'A news curator application that aggregates news articles from various sources.',
        imageType: 'XNews',
      },
      {
        title: 'QContest',
        content: 'A HackerRank-like platform for coding challenges.',
        imageType: 'QContest',
      },
      {
        title: 'Xlido',
        content: 'A Slido-like event polling and Q&A application.',
        imageType: 'XLido',
      },
      {
        title: 'XPoll',
        content: 'A polling application using JDBC and MySQL.',
        imageType: 'XPoll',
      },
      {
        title: 'QEats',
        content: 'A Swiggy-like food ordering application.',
        imageType: 'QEats',
      },
      {
        title: 'XMeme',
        content: 'A platform for posting and viewing memes.',
        imageType: 'XMeme',
      },
    ],
  },
];
