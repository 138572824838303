export const qaAutomationCurriculumMessages = [
  {
    message: 'Here is the curriculum of QA Automation that Crio is offering.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'QA Automation Fellowship Curriculum',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'grid',
    payload: [
      {
        title: 'Introduction to Programming and Java Foundations',
        content:
          'The curriculum starts with Java Foundations, where learners are introduced to core programming concepts like variables, loops, functions, and data structures. Learners practice Java syntax, covering loops, conditionals, arrays, and classes/objects, preparing them for more advanced topics in QA Automation and building a solid programming foundation.',
      },
      {
        title: 'Object-Oriented Programming (OOP) and Problem Solving',
        content:
          'In this section, learners delve into core OOP concepts such as inheritance, abstraction, polymorphism, and encapsulation. They apply these principles through practical exercises like building a simple calculator application. This section also covers Git and Linux basics, preparing learners for a collaborative development environment in test automation.',
      },
      {
        title: 'Web-Based Automation with Selenium',
        content:
          'This section introduces learners to web automation using Selenium, covering how to automate interactions with common web elements like buttons, text fields, and dropdowns. Learners practice using Selenium WebDriver to automate browser navigation and locate web elements using various locators. The section also covers test case management, bug tracking, and defect tracking tools.',
      },
      {
        title: 'Advanced Automation, Frameworks, and Performance Testing',
        content:
          'In this section, learners build advanced Selenium frameworks, integrate TestNG for test management, and use Jenkins for CI/CD. They also explore data-driven testing with Apache POI and practice automating complex scenarios using Selenium. Performance testing is covered with tools like JMeter and Postman for API testing, preparing learners for real-world automation and performance challenges.',
      },
      {
        title: 'Data Structures & Algorithms (DSA)',
        content:
          'Learners focus on building problem-solving skills using arrays, strings, and recursion. This section covers DSA topics such as time-space complexity, sliding window, and two-pointer techniques. Learners also explore core data structures like linked lists and binary trees, preparing them to solve real-world automation problems efficiently.',
      },
      {
        title: 'QA Automation Interview Preparation',
        content:
          'This section prepares learners for QA Automation Engineer interviews. They practice Java, test automation, and DSA problems commonly asked in interviews. Learners also work on mock interviews, solving aptitude questions, and preparing for HR interviews. The goal is to help learners showcase their skills confidently during interviews, optimize their resumes, and demonstrate their problem-solving capabilities.',
      },
    ],
  },
  {
    message: 'Industry-grade hands-on Projects',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'carousel',
    payload: [
      {
        title: 'QCalc',
        content:
          'Build a simple calculator application to practice Object-Oriented Programming principles in Java.',
        imageType: 'QCalc',
      },
      {
        title: 'QKart',
        content:
          'Automate web-based interactions like search bars, dropdowns, and common web elements using Selenium.',
        imageType: 'QKart',
      },
      {
        title: 'QKart: Performance',
        content:
          'Conduct load, spike, and stress testing with JMeter while monitoring application performance using SigNoz.',
        imageType: 'QKart',
      },
      {
        title: 'QTrip',
        content:
          'Develop a Selenium framework using advanced test automation techniques, including TestNG, reporting, and Jenkins integration.',
        imageType: 'QTrip',
      },
      {
        title: 'ZCommerce',
        content:
          'Test a distributed system architecture involving APIs, databases, and deployment using Swagger, Postman, Docker, and AWS.',
        imageType: 'ZCommerce',
      },
      {
        title: 'QScore',
        content:
          'Write tests for Kafka brokers while implementing BDD principles and learning CI/CD pipelines for robust system architecture testing.',
        imageType: 'QScore',
      },
    ],
  },
];
