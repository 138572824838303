import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y, Autoplay } from 'swiper';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ProjectCard } from './MessageTypes/ProjectCard';
import { ProfileCard } from './MessageTypes/ProfileCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation, A11y, Autoplay]);

export default function ChatSwiperCarousel({ items }) {
  const [isBeginning, setIsBeginning] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);
  const swiperRef = useRef(null);

  const renderCarouselItem = (item) => {
    // If it has project-specific properties, it's a project card
    if (item.projectLink || item.techStack) {
      return <ProjectCard project={item} />;
    }
    // If it has profile-specific properties, it's a profile card
    if (item.description && item.imageType) {
      return <ProfileCard profile={item} />;
    }
    return <ProjectCard project={item} />;
  };

  return (
    <div className="relative">
      <Swiper
        spaceBetween={16}
        slidesPerView={1.2}
        navigation={{
          prevEl: '.swiper-button-prev-custom',
          nextEl: '.swiper-button-next-custom',
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        className="!px-4"
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>{renderCarouselItem(item)}</SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons - Only shown conditionally */}
      {!isBeginning && (
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className="swiper-button-prev-custom absolute left-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-transparent p-2 text-black shadow-lg transition-colors hover:bg-[#3A3A3A]"
          aria-label="Previous slide"
        >
          <ChevronLeft size={20} />
        </button>
      )}
      {!isEnd && (
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className="swiper-button-next-custom absolute right-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-transparent p-2 text-black shadow-lg transition-colors hover:bg-[#3A3A3A]"
          aria-label="Next slide"
        >
          <ChevronRight size={20} />
        </button>
      )}

      <style jsx global>{`
        .swiper {
          padding: 0 32px !important;
        }

        /* Hide default Swiper navigation buttons */
        .swiper-button-next,
        .swiper-button-prev {
          display: none !important;
        }
      `}</style>
    </div>
  );
}
