import { OTHER_DEMO_SLOT } from '@src/constants/LeadGenerationConstants/index';
import * as yup from 'yup';

const commonPageTwoValidationSchema = {
  name: yup
    .string()
    .required('Name is a required field')
    .test(
      'noSpaceChars',
      'Name cannot be empty or contain only spaces',
      (value) => {
        return value && value.trim().length > 0;
      },
    ),

  highestQualification: yup
    .string()
    .required('Qualification is a required field'),

  graduationYear: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be a positive number')
    .integer('Must be an integer')
    .required('Graduation Year is a required field'),

  jobDomain: yup.string().when('currentlyWorking', {
    is: 'Yes',
    then: yup.string().required('Job Domain is a required field'),
    otherwise: yup.string().nullable(true),
  }),
  jobRole: yup.string().when('currentlyWorking', {
    is: 'Yes',
    then: yup.string().required('Job Role is a required field'),
    otherwise: yup.string().nullable(true),
  }),
};

// Regex for Indian phone numbers; TODO: create a seperate regex array for all-country validation
const indianRegex = new RegExp('^[6-9][0-9]{9}$');

export const getPageTwoSchema = (
  extendCompanyField,
  extendTrialSlot,
  isSde2Page,
  isFullStackPpcVl,
  isDemoSlotSelectionEnabled,
) =>
  yup.object().shape({
    ...commonPageTwoValidationSchema,
    ...(!isFullStackPpcVl && {
      currentlyWorking: yup.string().required('Profile is a required field'),
    }),
    ...(extendCompanyField
      ? {
          company: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup.string().required('Company is a required field'),
            otherwise: yup.string().nullable(true),
          }),
        }
      : {}),
    ...(extendTrialSlot
      ? {
          trialSlot: isDemoSlotSelectionEnabled
            ? yup
                .mixed()
                .required('Trial Slot is a required field')
                .test(
                  'is-valid',
                  'Trial Slot must be a valid Date',
                  (value) =>
                    (typeof value === 'string' && !isNaN(Date.parse(value))) ||
                    (value instanceof Date && !isNaN(value.getTime())) ||
                    value === OTHER_DEMO_SLOT,
                )
                .nullable()
            : yup
                .date()
                .required('Trial Slot is a required field')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
        }
      : {}),
    ...(isSde2Page
      ? {
          techStack: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup
              .string()
              .required('Tech Stack is a required field')
              .test(
                'noSpaceChars',
                'Tech Stack cannot be empty or contain only spaces',
                (value) => {
                  return value && value.trim().length > 0;
                },
              ),
            otherwise: yup.string().nullable(true),
          }),
          workExperience: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup.string().required('Work Experience is a required field'),
            otherwise: yup.string().nullable(true),
          }),
        }
      : {}),
  });

export const getOpenFormPageOneSchema = (
  extendCompanyField,
  extendTrialSlot,
  isSde2Page,
  isFullStackPpcVl,
  isDemoSlotSelectionEnabled,
  isOpenForm,
  isHomePage,
  isCampaignPage,
  isProvisionalEnrollment,
) =>
  yup.object().shape({
    ...(isOpenForm && {
      firstName: yup
        .string()
        .min(3, 'Must have minimum 3 characters')
        .required('Name is a required field')
        .test(
          'noSpaceChars',
          'First Name cannot be empty or contain only spaces',
          (value) => {
            return value && value.trim().length > 0;
          },
        ),
      email: yup
        .string()
        .email('Must be a valid email')
        .required('Email is a required field'),
      phone: yup
        .string()
        .required('Phone is a required field')
        .test('Check Indian Number', function () {
          let contact = this.parent['phone'];

          if (contact && contact.startsWith('91')) {
            let contactWithoutCountryCode = contact.substring(
              2,
              contact.length,
            );
            return indianRegex.test(contactWithoutCountryCode);
          }

          return contact.length >= 8; // temporary logic to validate at-least 8 digit phone number (8 digits include country code)
        }),
    }),
    ...(!isProvisionalEnrollment && {
      highestQualification: yup
        .string()
        .required('Qualification is a required field'),

      graduationYear: yup
        .string()
        .required('Graduation Year is a required field'),

      jobDomain: yup.string().when('currentlyWorking', {
        is: 'Yes',
        then: yup.string().required('Job Domain is a required field'),
        otherwise: yup.string().nullable(true),
      }),
      jobRole: yup.string().when('currentlyWorking', {
        is: 'Yes',
        then: yup.string().required('Job Role is a required field'),
        otherwise: yup.string().nullable(true),
      }),
    }),
    ...((isHomePage || isCampaignPage) && {
      programInterested: yup
        .string()
        .required('Program Interested is a required field'),
    }),
    ...(!isFullStackPpcVl &&
      !isProvisionalEnrollment && {
        currentlyWorking: yup.string().required('Profile is a required field'),
      }),
    ...(extendCompanyField
      ? {
          company: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup.string().required('Company is a required field'),
            otherwise: yup.string().nullable(true),
          }),
        }
      : {}),
    ...(extendTrialSlot
      ? {
          trialSlot: isDemoSlotSelectionEnabled
            ? yup
                .mixed()
                .required('Trial Slot is a required field')
                .test(
                  'is-valid',
                  'Trial Slot must be a valid Date',
                  (value) =>
                    (typeof value === 'string' && !isNaN(Date.parse(value))) ||
                    (value instanceof Date && !isNaN(value.getTime())) ||
                    value === OTHER_DEMO_SLOT,
                )
                .nullable()
            : yup
                .date()
                .required('Trial Slot is a required field')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
        }
      : {}),
    ...(isSde2Page
      ? {
          techStack: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup
              .string()
              .required('Tech Stack is a required field')
              .test(
                'noSpaceChars',
                'Tech Stack cannot be empty or contain only spaces',
                (value) => {
                  return value && value.trim().length > 0;
                },
              ),
            otherwise: yup.string().nullable(true),
          }),
          workExperience: yup.string().when('currentlyWorking', {
            is: 'Yes',
            then: yup.string().required('Work Experience is a required field'),
            otherwise: yup.string().nullable(true),
          }),
        }
      : {}),
  });
