/**
 * Chatbot Component
 *
 * Flow:
 * 1. User selects an option from the chat
 * 2. Option triggers a response flow:
 *    - Shows user's selection
 *    - Gets bot's responses
 *    - Checks for special messages based on query count
 *
 * Special Message Flow (at specific query counts):
 * 1. Shows inserted messages
 * 2. Enables text input for user
 * 3. After user types:
 *    - Shows their message
 *    - Continues with bot responses
 *    - Shows follow-up questions
 *
 * Normal Flow:
 * 1. Shows bot responses directly
 * 2. Shows follow-up questions if any
 *
 * State Management:
 * - Maintains conversation history in session storage
 * - Tracks user query count
 * - Handles loading states and typing indicators
 * - Manages message input availability
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  Avatar,
  // Button,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  createBotMessage,
  createOptionsFromFlow,
  getNextQuestion,
  getResponse,
} from '../utils/chatUtils';
import { formatResponseToMessages } from '../utils/messageFormatter';
import { ChatControls } from './ChatControls';
import { ChatOptions } from './MessageTypes/ChatOptions';
import ChatSwiperCarousel from './ChatSwiperCarousel';
import { ChatGrid } from './ChatGrid';
import { ChatSection } from './MessageTypes/ChatSection';
import {
  createNewConversation,
  getCurrentConversation,
  updateConversation,
} from '../utils/conversationManager';
import {
  shouldInsertMessage,
  getInsertedMessages,
  validateMessage,
  INSERT_MESSAGE_TYPE,
} from '../utils/messageInserter';
import { ChatSectionWithCarousel } from './MessageTypes/ChatSectionWithCarousel';
import { ChatCallToAction } from './MessageTypes/ChatCallToAction';
import { ChatSectionWithSubSection } from './MessageTypes/ChatSectionWithSubSection';
import { ChatRedirectButton } from './MessageTypes/ChatRedirectButton';
import chatLogo from '../../../images/Chatbot/chatLogo.png';
import { ChatQuestionGroup } from './MessageTypes/ChatQuestionGroup';
import { ChatResponseGroup } from './MessageTypes/ChatResponseGroup';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { CHATBOT_EVENTS } from '../constants/events';
import { GTM } from '@src/analytics/gtm';

export const Chatbot = ({ onClose }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [messages, setMessages] = useState([]);
  const [isTypingActive, setIsTypingActive] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [userQueryCount, setUserQueryCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [currentValidation, setCurrentValidation] = useState(null);
  const [allowManualTyping, setAllowManualTyping] = useState(false);

  const handleSendRef = React.useRef(null);
  const latestUserMessageRef = useRef(null);

  const [messageStats, setMessageStats] = useState({
    userMessageCount: 0,
    botMessageCount: 0,
    lastUserMessage: '',
    lastBotMessage: '',
    lastUserMessageTime: null,
    lastBotMessageTime: null,
  });

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];

      if (lastMessage.sender === 'user') {
        setMessageStats((prev) => ({
          ...prev,
          userMessageCount: prev.userMessageCount + 1,
          lastUserMessage: lastMessage.message,
          lastUserMessageTime: lastMessage.sentTime,
        }));
      } else {
        // Find the last actual response from bot (excluding follow-up questions)
        let lastBotResponse = null;
        for (let i = messages.length - 1; i >= 0; i--) {
          const msg = messages[i];
          const nextMsg = messages[i + 1];

          // Skip if this is a question followed by options (follow-up question)
          if (msg.type === 'text' && nextMsg?.type === 'options') {
            continue;
          }

          // Found the last actual bot response
          if (msg.sender === 'bot' && msg.type !== 'options') {
            lastBotResponse = msg;
            break;
          }
        }

        if (lastBotResponse) {
          setMessageStats((prev) => ({
            ...prev,
            botMessageCount: prev.botMessageCount + 1,
            lastBotMessage: lastBotResponse.message,
            lastBotMessageTime: lastBotResponse.sentTime,
          }));
        }
      }
    }
  }, [messages]);

  const handleChatClose = () => {
    GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
      type: CHATBOT_EVENTS.CHAT_CLOSED.type,
      location: CHATBOT_EVENTS.CHAT_CLOSED.locations.CHAT_WINDOW_CLOSED,
      chatbotMessage: messageStats.lastBotMessage,
      chatbotMessageOrder: messageStats.botMessageCount,
      chatbotMessageTime: messageStats.lastBotMessageTime,
      userMessage: messageStats.lastUserMessage,
      userMessageOrder: messageStats.userMessageCount,
      userMessageSentTime: messageStats.lastUserMessageTime,
    });
    onClose?.();
  };

  useEffect(() => {
    const handleWindowClose = (e) => {
      GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
        type: CHATBOT_EVENTS.CHAT_CLOSED.type,
        location: CHATBOT_EVENTS.CHAT_CLOSED.locations.BROWSER_CHAT_CLOSED,
        chatbotMessage: messageStats.lastBotMessage,
        chatbotMessageOrder: messageStats.botMessageCount,
        chatbotMessageTime: messageStats.lastBotMessageTime,
        userMessage: messageStats.lastUserMessage,
        userMessageOrder: messageStats.userMessageCount,
        userMessageSentTime: messageStats.lastUserMessageTime,
      });
    };

    window.addEventListener('beforeunload', handleWindowClose);
    return () => window.removeEventListener('beforeunload', handleWindowClose);
  }, [messageStats]);

  const handleResetChat = () => {
    GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
      type: CHATBOT_EVENTS.CHAT_CLICK.type,
      location: CHATBOT_EVENTS.CHAT_CLICK.locations.RESET_CHAT,
      chatbotMessageOrder: messageStats.botMessageCount,
      chatbotMessageTime: messageStats.lastBotMessageTime,
    });
    const newConversation = createNewConversation();
    setConversationId(newConversation.id);
    setMessages([]);
    setIsTypingActive(false);
    setUserQueryCount(0);
    initializeChat();
  };

  const initializeChat = () => {
    const existingConversation = getCurrentConversation();

    if (existingConversation && existingConversation.messages.length > 0) {
      setConversationId(existingConversation.id);
      setMessages(existingConversation.messages);
      setUserQueryCount(existingConversation.userQueryCount || 0);
    } else {
      const newConversation = createNewConversation();
      setConversationId(newConversation.id);
      setUserQueryCount(0);

      const initialFlow = getNextQuestion('q1');
      if (initialFlow) {
        const options = createOptionsFromFlow(initialFlow);
        const initialMessages = [
          createBotMessage(initialFlow.question, 'text'),
          createBotMessage('How can I help you?', 'options', options),
        ];
        setMessages(initialMessages);
        updateConversation(initialMessages);
      }
    }
  };

  useEffect(() => {
    initializeChat();
  }, []);

  const handleReset = () => {
    const newConversation = createNewConversation();
    setConversationId(newConversation.id);
    setMessages([]);
    setIsTypingActive(false);
    setUserQueryCount(0);
    initializeChat();
  };

  const handleOptionSelect = async (value) => {
    // Prevent multiple simultaneous requests
    if (isProcessing || isTyping) return;
    console.log('handleOptionSelect', value);

    const response = getResponse(value);
    if (!response) return;

    // Set processing states to show loading indicators
    setIsProcessing(true);
    setIsTyping(true);

    // Increment and track user interaction count
    const newQueryCount = userQueryCount + 1;
    setUserQueryCount(newQueryCount);

    // Create and display user's selected option as a message
    const userMessage = {
      message: response.text,
      sentTime: new Date().toISOString(),
      sender: 'user',
      direction: 'outgoing',
      position: 'single',
      type: 'text',
    };

    // Update messages with user's selection
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, userMessage];
      updateConversation(newMessages, true);
      return newMessages;
    });

    // Handle bot response flow
    if (response.selectionResponseId) {
      const botResponses = formatResponseToMessages(
        response.selectionResponseId,
      );

      // Define handleRestOfFlow here, outside of the validation block
      const handleRestOfFlow = () => {
        setIsTyping(true);

        setTimeout(() => {
          // First show the original bot responses for the selected option
          const originalResponses = formatResponseToMessages(
            response.selectionResponseId,
          );

          setMessages((prevMsgs) => {
            let newMessages = [...prevMsgs];

            // Add the original responses first
            newMessages = [...newMessages, ...originalResponses];

            // Then add follow-up question if exists
            if (response.followupQuestionId) {
              const nextFlow = getNextQuestion(response.followupQuestionId);
              if (nextFlow) {
                const options = createOptionsFromFlow(nextFlow);
                newMessages = [
                  ...newMessages,
                  createBotMessage(nextFlow.question, 'text'),
                  createBotMessage(
                    'Please select an option:',
                    'options',
                    options,
                  ),
                ];
              }
            }

            updateConversation(newMessages);
            return newMessages;
          });

          // Reset all processing states
          setIsTyping(false);
          setIsProcessing(false);
          setIsTypingActive(false);
          setValidationError(null);
          setCurrentValidation(null);
        }, 2000);
      };

      setTimeout(() => {
        setMessages((prevMessages) => {
          let newMessages = [...prevMessages];

          if (shouldInsertMessage(newQueryCount)) {
            const { messages: insertedMessages, config } =
              getInsertedMessages(newQueryCount);
            newMessages = [...newMessages, ...insertedMessages];

            if (config.type === INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED) {
              setCurrentValidation(config.validation);
              setIsTypingActive(true);

              handleSendRef.current = (message) => {
                if (!message.trim()) return;

                // Clean the input based on validation type
                const cleanedMessage =
                  currentValidation?.type === 'phone'
                    ? message.replace(/[- ]/g, '')
                    : message;

                // Show user's message
                const userTextMessage = {
                  message: message,
                  sentTime: new Date().toISOString(),
                  sender: 'user',
                  direction: 'outgoing',
                  position: 'single',
                  type: 'text',
                };

                // Add user message
                setMessages((prevMessages) => {
                  const newMessages = [...prevMessages, userTextMessage];
                  updateConversation(newMessages, true);
                  return newMessages;
                });

                // Start typing indicator
                setIsTyping(true);

                // Validate input
                const validationResult = validateMessage(
                  cleanedMessage,
                  config?.validation,
                  config,
                  dispatch,
                  state,
                );

                setTimeout(() => {
                  if (!validationResult.isValid) {
                    // Show error messages
                    const botMessages = [
                      {
                        message: validationResult.errorMessage,
                        sentTime: new Date().toISOString(),
                        sender: 'bot',
                        direction: 'incoming',
                        position: 'single',
                        type: 'text',
                      },
                      {
                        message:
                          config?.validation?.type === 'email'
                            ? 'Please enter your email address again:'
                            : 'Please enter your phone number again:',
                        sentTime: new Date().toISOString(),
                        sender: 'bot',
                        direction: 'incoming',
                        position: 'single',
                        type: 'text',
                      },
                    ];

                    setMessages((prevMessages) => {
                      const newMessages = [...prevMessages, ...botMessages];
                      updateConversation(newMessages);
                      return newMessages;
                    });

                    // Update error states
                    setValidationError(validationResult.errorMessage);
                    setIsTypingActive(true);
                    setIsTyping(false);
                  } else {
                    // Valid input - proceed with flow
                    setValidationError(null);
                    setCurrentValidation(null);
                    setIsTypingActive(false);
                    handleRestOfFlow();
                  }
                }, 2000);
              };

              return newMessages;
            }

            // Normal flow without validation
            newMessages = [...newMessages, ...botResponses];

            // Add follow-up question if exists
            if (response.followupQuestionId) {
              const nextFlow = getNextQuestion(response.followupQuestionId);
              if (nextFlow) {
                const options = createOptionsFromFlow(nextFlow);
                newMessages = [
                  ...newMessages,
                  createBotMessage(nextFlow.question, 'text'),
                  createBotMessage(
                    'Please select an option:',
                    'options',
                    options,
                  ),
                ];
              }
            }

            // Update conversation state
            updateConversation(newMessages);
            return newMessages;
          }

          // Normal flow without validation
          newMessages = [...newMessages, ...botResponses];

          // Add follow-up question if exists
          if (response.followupQuestionId) {
            const nextFlow = getNextQuestion(response.followupQuestionId);
            if (nextFlow) {
              const options = createOptionsFromFlow(nextFlow);
              newMessages = [
                ...newMessages,
                createBotMessage(nextFlow.question, 'text'),
                createBotMessage(
                  'Please select an option:',
                  'options',
                  options,
                ),
              ];
            }
          }

          // Update conversation state
          updateConversation(newMessages);
          return newMessages;
        });

        // Reset processing states
        setIsTyping(false);
        setIsProcessing(false);
      }, 2000);
    }
  };

  const handleManualTypeClick = () => {
    setIsTypingActive(true);
    setAllowManualTyping(true);
    handleSendRef.current = (message) => {
      if (!message.trim()) return;

      const userTextMessage = {
        message: message,
        sentTime: new Date().toISOString(),
        sender: 'user',
        direction: 'outgoing',
        position: 'single',
        type: 'text',
      };

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, userTextMessage];
        updateConversation(newMessages, true);
        return newMessages;
      });
    };
  };

  useEffect(() => {
    if (latestUserMessageRef.current) {
      // Find the scrollable container
      const scrollContainer = document.querySelector(
        '.cs-message-list__scroll-wrapper',
      );
      if (scrollContainer) {
        // Get the user message position relative to the scroll container
        const userMessagePosition = latestUserMessageRef.current.offsetTop;

        // Scroll the container
        scrollContainer.scrollTop = userMessagePosition - 100; // 100px offset from top
      }
    }
  }, [messages]);

  const renderMessage = (msg, i, messages) => {
    const isLastOptions = msg.type === 'options' && i === messages.length - 1;

    return (
      <Message
        key={i}
        model={{
          message: msg.message,
          sentTime: msg.sentTime,
          sender: msg.sender,
          direction: msg.direction,
          position: msg.position,
        }}
        className="text-[#FFFFFF]"
      >
        {msg.type !== 'text' && msg.payload && (
          <Message.CustomContent>
            {msg.type === 'options' && (
              <ChatOptions
                items={msg.payload}
                onSelect={handleOptionSelect}
                disabled={!isLastOptions || isProcessing || isTyping}
              />
            )}
            {msg.type === 'carousel' && (
              <ChatSwiperCarousel items={msg.payload} />
            )}
            {msg.type === 'grid' && <ChatGrid items={msg.payload} />}
            {msg.type === 'section' && <ChatSection items={msg.payload} />}
            {msg.type === 'section_with_carousel' && (
              <ChatSectionWithCarousel items={msg.payload} />
            )}
            {msg.type === 'callToAction' && (
              <ChatCallToAction
                text={msg.payload.text}
                link={msg.payload.link}
              />
            )}
            {msg.type === 'section_with_sub_section' && (
              <ChatSectionWithSubSection items={msg.payload} />
            )}
            {msg.type === 'redirectButton' && (
              <ChatRedirectButton
                text={msg.payload.text}
                link={msg.payload.link}
              />
            )}
          </Message.CustomContent>
        )}
      </Message>
    );
  };

  const renderMessages = (messages) => {
    const messageElements = [];
    let currentResponseGroup = [];

    const addResponseGroup = () => {
      if (currentResponseGroup.length > 0) {
        messageElements.push(
          <ChatResponseGroup
            key={`response-group-${messageElements.length}`}
            messages={currentResponseGroup}
          />,
        );
        currentResponseGroup = [];
      }
    };

    for (let i = 0; i < messages.length; i++) {
      const msg = messages[i];
      const nextMsg = messages[i + 1];

      if (msg.type === 'text' && nextMsg?.type === 'options') {
        addResponseGroup();
        messageElements.push(
          <ChatQuestionGroup
            key={`group-${i}`}
            question={msg}
            options={nextMsg}
            onSelect={handleOptionSelect}
            isProcessing={isProcessing}
            isTyping={isTyping}
          />,
        );
        i++;
      } else if (msg.direction === 'outgoing') {
        addResponseGroup();
        // Wrap user message in a div with ref
        messageElements.push(
          <div key={`user-message-${i}`} ref={latestUserMessageRef}>
            {renderMessage(msg, i, messages)}
          </div>,
        );
      } else {
        currentResponseGroup.push(msg);
      }
    }

    addResponseGroup();
    return messageElements;
  };

  return (
    <div className="fixed bottom-0 right-0 h-full w-full overflow-hidden bg-gray-900 shadow-xl md:bottom-12 md:right-12 md:h-[70vh] md:w-[540px] md:rounded-xl">
      <MainContainer className="dark-theme">
        <ChatContainer>
          <ConversationHeader className="dark-header">
            <Avatar src={chatLogo} name="Kiran" />
            <ConversationHeader.Content
              userName="Hi! How may I help you?"
              className="dark-header text-white"
            />
            <ConversationHeader.Actions>
              <ChatControls
                onClose={() => {
                  handleChatClose();
                  onClose();
                }}
                onReset={() => {
                  handleResetChat();
                  handleReset();
                }}
              />
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            className="dark-message-list"
            typingIndicator={
              isTyping ? (
                <TypingIndicator
                  content="Kiran is typing"
                  className="text-black"
                />
              ) : null
            }
          >
            {renderMessages(messages)}
          </MessageList>
          {isTypingActive && (
            <MessageInput
              placeholder={
                currentValidation?.type === 'phone'
                  ? 'Enter your phone number...'
                  : 'Type here...'
              }
              onSend={(message) => handleSendRef.current?.(message)}
              disabled={isProcessing || isTyping}
              className="dark-message-input text-white"
            />
          )}
          {validationError && (
            <div className="px-4 py-1 text-sm text-red-500">
              {validationError}
            </div>
          )}
        </ChatContainer>
      </MainContainer>

      <style jsx global>{`
        /* Reset and base styles */
        .cs-main-container,
        .cs-chat-container,
        .cs-message-list,
        .cs-message-list__scroll-wrapper {
          background-color: #ffffff !important;
        }

        /* Header styles */
        .cs-conversation-header,
        .dark-header {
          background-color: #013437 !important;
          border-bottom: none !important;
        }

        .cs-conversation-header *,
        .cs-conversation-header__content,
        .cs-conversation-header__user-name {
          color: #ffffff !important;
          background-color: #013437 !important;
          border: none !important;
        }

        /* Remove any underlines */
        .cs-conversation-header__content::after,
        .cs-conversation-header__content::before {
          display: none !important;
        }

        /* Base message styles */
        .cs-message__content {
          padding: 12px 16px !important;
        }

        /* Outgoing messages (user) */
        .cs-message--outgoing {
          display: flex !important;
          justify-content: flex-end !important;
          padding-right: 16px !important;
          margin: 8px 0 !important;
          width: 100% !important;
        }

        .cs-message--outgoing .cs-message__content-wrapper {
          margin: 2px 0px !important;
          background: transparent !important;
          padding: 0 !important;
          border-radius: 0 !important;
          width: fit-content !important;
          display: flex !important;
          flex-direction: row-reverse !important;
        }

        .cs-message--outgoing .cs-message__content {
          background-color: #2df8c5 !important;
          color: #000000 !important;
          border-radius: 16px !important;
          margin-left: auto !important;
          margin: 8px 0px !important;
          width: auto !important;
        }

        /* Remove any container constraints */
        .cs-message-list__scroll-wrapper {
          width: 100% !important;
        }

        .cs-message__content-wrapper {
          width: 100% !important;
        }

        /* Message container */
        .cs-message {
          width: 100% !important;
          max-width: none !important;
          padding: 0 !important;
        }

        /* Incoming messages (bot) */
        .cs-message--incoming .cs-message__content {
          border-radius: 16px !important;
          color: #ffffff !important;
        }

        /* Message spacing */
        .cs-message {
          margin: 4px 0 !important;
        }

        /* Input area styles */

        .cs-message-input__content-editor-wrapper,
        .cs-message-input__content-editor {
          margin: 0px 10px !important;
          border-radius: 10px !important;
          background-color: #efefef !important;
          color: #000000 !important;
        }

        .cs-message-input__content-editor[data-placeholder]:empty:before {
          color: #000000 !important;
          opacity: 0.7;
        }

        .cs-message-input__content-editor-container {
          background-color: #efefef !important;
        }

        .cs-message-input__tools > *:first-child {
          display: none !important;
        }

        .cs-message--incoming {
          margin: 0 !important;
        }

        /* Button styles */
        .cs-button {
          color: #ffffff !important;
        }

        .cs-button--send {
          color: #3b82f6 !important;
        }

        /* Typing indicator */
        .cs-typing-indicator {
          background-color: transparent !important;
          color: black !important;
        }

        .cs-typing-indicator__dot {
          background-color: black !important;
        }

        .cs-typing-indicator__text {
          color: black !important;
        }

        /* Options and interactive elements */
        .cs-message-list__typing-indicator {
          background-color: #333333 !important;
          color: #ffffff !important;
        }

        /* Incoming messages (bot) - within wrapper */
        .cs-message--incoming .cs-message__content {
          background-color: #b9cfca !important;
          border-radius: 8px !important;
          color: #000000 !important;
        }

        /* Remove default message margins for bot messages (handled by wrapper) */
        .cs-message--incoming {
          margin: 0 !important;
        }

        /* Keep margins for user messages */
        .cs-message--outgoing {
          margin: 8px 0 !important;
        }

        /* Add or update the MessageList styles */
        .cs-message-list {
          padding-bottom: 20px !important;
        }
      `}</style>
    </div>
  );
};
