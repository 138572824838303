// import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useEffect } from 'react';
import Navigation from '@components/Navigation/index';
import FooterV2 from '@components/FooterV2/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import ProjectsSection from '@components/v5/ProjectsSection/index';
// import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import CrioComparisionSection from '@components/v5/CrioComparisionSection/index';
import Testimonial from '@components/v5/Testimonial/index';
import FAQSection from '@components/v5/FAQSection/index';
// import StepsSection from '@components/v5/StepsSection/index';
// import WhyCrioSectionV2 from '@components/v5/WhyCrioSectionV2/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CrioButton from '@components/v5/CrioButton/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import SaleMiddleStrip from '@components/v5/Sale/SaleMiddleStrip';
import { stripData } from '@components/Sale/data';
import FspStylePageHero from '@components/v5/FspStylePageHero/index';
import StatsPanelFSP from '@components/v5/StatsPanelFSP/index';
import FspPricingCardSection from '@components/v5/FspPricingCardSection/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_WHY_CRIO,
  LEAD_GEN_PROJECTS,
  // LEAD_GEN_PROGRAM_STEPS,
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_CRIO_COMPARISION,
  LEAD_GEN_FAQ,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  // LEAD_GEN_PLAN_SECTION,
} from '../constants/LeadGenerationConstants/index';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GTM } from '@src/analytics/gtm';
// import PersonalizedPlan from '@components/v5/PersonalizedPlan/index';
// import TechnologiesMasterSection from '@components/v5/TechnologiesMasterSection/index';
import fsp from '../seo/jsonld/fsd.json';
import fdt from '../seo/jsonld/fdt.json';
import bdt from '../seo/jsonld/bdt.json';
import dataScienceJsonLd from '../seo/jsonld/data-science.json';
// import JobGuaranteeCard from '@components/v5/JobGuaranteeCard/index';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import useCrioStat from '@src/hooks/useCrioStats';
// import WhyCrioSectionV3 from '@components/v5/WhyCrioSectionV3/index';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import CurriculumV4 from '@components/v5/CurriculumV4/index';
import SkillSection from '@components/v5/SkillSection/index';
import BottomStrip from '@components/PPCNavbar/BottomStrip';
import MentorTestimonialsSection from '@components/MentorSection';
// import CompanySection from '@components/v5/CompanySection/index';

export default function FspStylePage(props) {
  const { captureUserClickEvent } = useWebEngage();
  const state = useContext(GlobalStateContext);

  const { isDataSciencePage } = useDataScienceProgram();

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'FSP_PAGE',
    });
    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.SD_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED,
      state.email,
    );
  };
  const { data: queryData } = props;

  const { dataAnalyticsStylePagesYaml: data } = queryData;
  console.log('🚀 ~ FspStylePage ~ data:', props, queryData);
  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID == data.PrimaryProgramID,
  );

  const programId = program?.PrimaryProgramID;
  const jsonLd =
    programId === EProgramIDs.DATA_SCIENCE
      ? dataScienceJsonLd
      : programId === EProgramIDs.FULL_STACK
      ? fdt
      : programId === EProgramIDs.FELLOWSHIP
      ? fsp
      : bdt;

  const sale = props.data.strapiSale.SaleID;

  const { placementStats } = useCrioStat();

  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return (
          <div className="mx-4 mb-10 flex justify-center">
            <SaleMiddleStrip data={stripData[program.PrimaryProgramID]}>
              <LeadGenerationButton
                type="Book Your Trial, Now"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </SaleMiddleStrip>
          </div>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    //webengage events specific to FSD Page(experiment):
    GTM.track(gtmEvents.PAGE_VIEW_FSD, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });

    //GA EVENT FOR PAGE VIEW:
    GTM.track(gtmEvents.PAGE_VIEW, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });
  }, []);

  return (
    <div>
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Book Your Trial, Now"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <Navigation
        program={program.PrimaryProgramID}
        sale={sale}
        hideCTA={isDataSciencePage}
      ></Navigation>
      <div className="">
        <div className="">
          <FspStylePageHero
            program={program}
            title={data.heroTitle}
            image={data.heroImage.childImageSharp}
            description={data.heroDescription}
            subTitle={data.heroSubtitle}
            stats={[]}
            statsSubtitle={data.statsSubtitle}
            sale={sale}
          ></FspStylePageHero>
        </div>
        <CurriculumV4
          heading={data.curriculumTitle}
          description={data.curriculumDescription}
          quote={data.curriculumQuote}
          type={data.PrimaryProgramID}
          curriculum={data.curriculum}
          advancedCurriculum={data.advancedCurriculum}
          dreamCompanies={data.dreamCompanies}
          superDreamCompanies={data.superDreamCompanies}
        />

        <MentorTestimonialsSection />

        <WhyCrioSection
          heading={data.highlightsTitle}
          description={data.highlightsDescription}
          quote={data.highlightsQuote}
          data={data.highlights}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                text={
                  <div className="pr-2 text-left">
                    <h6 className="mb-1 font-manrope text-[18px] font-bold leading-[18px]">
                      Apply & Start for free
                    </h6>
                  </div>
                }
                iconClassName={`text-3xl`}
                className={`h-[77px]`}
                buttonLocation={LEAD_GEN_WHY_CRIO}
              />
            </PostContentCTALayout>
          }
        ></WhyCrioSection>

        <CrackedCrioCareersSection
          postContent={
            <PostContentCTALayout>
              <div>
                <div className="-mb-10">
                  <StatsPanelFSP
                    stats={placementStats.map((stat) => {
                      return {
                        title: stat.content,
                        subtitle: `${stat.label}`,
                      };
                    })}
                  ></StatsPanelFSP>
                </div>
                <PostContentCTALayout className="mb-10">
                  <Link to={ERoutes.PLACEMENTS}>
                    <CrioButton
                      variant="secondary"
                      onClick={onPlacementButtonClick}
                      className="h-[64px] w-[300px] border-v5-neutral-600 py-6 text-v5-neutral-600 sm:w-auto"
                    >
                      Get our Placement Stats
                    </CrioButton>
                  </Link>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    className="h-[64px] w-[300px]"
                    buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                  />
                </PostContentCTALayout>
              </div>
            </PostContentCTALayout>
          }
        ></CrackedCrioCareersSection>

        <SkillSection dreamCompanies={data.skills} />

        <div className="min-h-[800px] py-10">
          <ProjectsSection
            heading={data.projectsTitle}
            description={data.projectsDescriptionV2}
            projects={data.projects}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Book Your Trial, Now"
                  buttonLocation={LEAD_GEN_PROJECTS}
                />
              </PostContentCTALayout>
            }
            preContent={getProjectsSectionPreContent(sale)}
          ></ProjectsSection>
        </div>

        <WhyCrioSection
          heading={data.careerTitleV2}
          description={data.careerDescription}
          data={data.careers}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Book Your Trial, Now"
                buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
              />
            </PostContentCTALayout>
          }
        ></WhyCrioSection>

        <FspPricingCardSection
          price={data.price}
          duration={data.priceDuration}
          sale={sale}
          program={program.PrimaryProgramID}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Book Your Trial, Now"
                buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
              />
            </PostContentCTALayout>
          }
        ></FspPricingCardSection>

        <VideoTestimonialSection
          quote={
            isDataSciencePage ? (
              <>
                Get more insights on how Crio can help you{' '}
                <br class="hidden sm:block" />
                crack a data analytics role in top tech companies.
              </>
            ) : (
              data.testimonialQuote
            )
          }
          disableCareerHandbook={
            data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
          }
          postContent={
            <PostContentCTALayout>
              <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                <LeadGenerationButton
                  type="Book Your Trial, Now"
                  buttonLocation={LEAD_GEN_TESTIMONIALS}
                />
              </div>
            </PostContentCTALayout>
          }
        ></VideoTestimonialSection>
        <CrioComparisionSection
          postContent={
            <div className="-mt-12">
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Book Your Trial, Now"
                  buttonLocation={LEAD_GEN_CRIO_COMPARISION}
                />
              </PostContentCTALayout>
              {!isDataSciencePage ? (
                <div className="container mt-16">
                  <div className="flex flex-col gap-x-10 md:flex-row">
                    <Testimonial dark></Testimonial>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          }
        ></CrioComparisionSection>
      </div>

      <FAQSection
        data={data.faqs}
        postContent={
          <PostContentCTALayout>
            <LeadGenerationButton
              type="Book Your Trial, Now"
              buttonLocation={LEAD_GEN_FAQ}
            />
          </PostContentCTALayout>
        }
      ></FAQSection>
      <FooterV2></FooterV2>
      <BottomStrip />
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    dataAnalyticsStylePagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      SEOTitle
      canonicalUrl
      SEODescription

      metatags {
        property
        content
      }

      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      statsSubtitle

      highlightsTitle
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription
      projectsDescriptionV2

      careerTitle
      careerTitleV2
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      skills {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor
      }

      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
