export const programMessages = [
  {
    message:
      "Choosing the best career path among Full Stack Development, Backend Development, QA Automation, System Design, and Data Analytics depends on your interests, goals, and current skills. Here's a brief overview to help you decide:",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Career Path Overview',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section_with_carousel',
    payload: [
      {
        title: 'Full Stack Development',
        content:
          "It offers versatility in building complete web applications, from user interfaces to server-side logic. It is ideal if you're looking for a holistic approach to development, as you'll handle a wide range of tasks, from databases to UI.",
        type: 'carousel',
        payload: [
          {
            imageType: 'AdrizaMishra',
            title: 'Adriza Mishra',
            description: 'Fresher to Developer at Honeywell',
          },
          {
            imageType: 'SuryaSaini',
            title: 'Surya Saini',
            description: 'Career Break to Developer at Mobikwik',
          },
          {
            imageType: 'LopamudraRath',
            title: 'Lopamudra Rath',
            description: 'Career Break to Developer at Target',
          },
        ],
      },
      {
        title: 'Backend Development',
        content:
          'Backend development focuses on building the server side of applications, handling databases, APIs, and ensuring data flow between users and servers. This is suited for those who prefer logical problem-solving and managing systems behind the scenes. Backend skills are in high demand.',
        type: 'carousel',
        payload: [
          {
            imageType: 'SubitDutta',
            title: 'Subit Dutta',
            description: 'System Engineer to Developer at SONY',
          },
          {
            imageType: 'AyushSinha',
            title: 'Ayush Sinha',
            description: 'Career Break to Developer at Swiggy',
          },
          {
            imageType: 'RitikBhatt',
            title: 'Ritik Bhatt',
            description: 'Support to Developer at Aristocrat',
          },
        ],
      },
      {
        title: 'QA Automation (SDET)',
        content:
          "QA Automation is essential for testing software applications efficiently using automated scripts. It's a great career for those interested in ensuring product reliability and having a more structured approach to coding.",
        type: 'carousel',
        payload: [
          {
            imageType: 'KanchanRathore',
            title: 'Kanchan Rathore',
            description: 'Support to QA at Carnot',
          },
          {
            imageType: 'PijushGhosh',
            title: 'Pijush Ghosh',
            description: 'Career Break to QA at Elphill Technologies',
          },
          {
            imageType: 'AbhishekSingh',
            title: 'Abhishek Kumar Singh',
            description: 'Manual QA to Automation QA at Kommunicate',
          },
        ],
      },
      {
        title: 'System Design',
        content:
          "This track is ideal if you have some development experience and want to advance into senior roles by mastering system design principles like High-Level Design (HLD) and Low-Level Design (LLD). It focuses on building large-scale distributed systems, so it's a good fit if you aim for senior development or architecture roles.",
        type: 'text',
      },
      {
        title: 'Data Analytics',
        content:
          'Data Analytics is a great option if you are inclined toward data-driven insights and enjoy working with data to make decisions. It involves data mining, data visualization, and using tools to uncover trends. If you like working with statistics and solving business problems through data, this could be your path.',
        type: 'text',
      },
    ],
  },
  {
    message:
      "For further clarity, I recommend trying out Crio's free trial, where you can get a feel for these domains and discuss the best path for you with a program advisor.",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'text',
  },
  {
    message: "Try Crio's free trial",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: "Try Crio's free trial",
    },
  },
];
