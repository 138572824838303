import React from 'react';
import RawHTML from '@components/Common/RawHTML';

export const ChatSection = ({ items }) => {
  return (
    <div className="-mx-2 flex flex-col gap-3 p-2 md:-mx-4 md:gap-4 md:p-2">
      {items.map((section, index) => (
        <div key={index} className="rounded-lg bg-transparent p-3 md:p-4">
          <RawHTML className="mb-2 text-[14px] font-semibold text-black">
            {section.title}
          </RawHTML>
          <RawHTML className="text-sm text-black">{section.content}</RawHTML>
        </div>
      ))}
    </div>
  );
};
