import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import { ChatOptions } from './ChatOptions';

export const ChatQuestionGroup = ({
  question,
  options,
  onSelect,
  isProcessing,
  isTyping,
}) => {
  return (
    <div className="mt-2 max-w-[85%] rounded-lg bg-[#F2FBF7] p-4">
      <div className="flex flex-col gap-4">
        <Message
          model={{
            message: question.message,
            sentTime: question.sentTime,
            sender: question.sender,
            direction: question.direction,
            position: question.position,
          }}
          className="text-black"
        />
        <ChatOptions
          items={options.payload}
          onSelect={onSelect}
          disabled={isProcessing || isTyping}
        />
      </div>
    </div>
  );
};
