import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import ChatSwiperCarousel from '../ChatSwiperCarousel';
import { ChatGrid } from '../ChatGrid';
import { ChatSection } from './ChatSection';
import { ChatSectionWithCarousel } from './ChatSectionWithCarousel';
import { ChatCallToAction } from './ChatCallToAction';
import { ChatSectionWithSubSection } from './ChatSectionWithSubSection';
import { ChatRedirectButton } from './ChatRedirectButton';

export const ChatResponseGroup = ({ messages }) => {
  const renderMessageContent = (msg, isProcessing, isTyping) => {
    if (msg.type === 'text') {
      return null;
    }

    return (
      <Message.CustomContent>
        {msg.type === 'carousel' && <ChatSwiperCarousel items={msg.payload} />}
        {msg.type === 'grid' && <ChatGrid items={msg.payload} />}
        {msg.type === 'section' && <ChatSection items={msg.payload} />}
        {msg.type === 'section_with_carousel' && (
          <ChatSectionWithCarousel items={msg.payload} />
        )}
        {msg.type === 'callToAction' && (
          <ChatCallToAction text={msg.payload.text} link={msg.payload.link} />
        )}
        {msg.type === 'section_with_sub_section' && (
          <ChatSectionWithSubSection items={msg.payload} />
        )}
        {msg.type === 'redirectButton' && (
          <ChatRedirectButton text={msg.payload.text} link={msg.payload.link} />
        )}
      </Message.CustomContent>
    );
  };

  return (
    <div className="my-2 mb-4 max-w-[85%] rounded-lg bg-[#F2FBF7] p-4">
      <div className="flex flex-col gap-4">
        {messages.map((msg, index) => (
          <Message
            key={index}
            model={{
              message: msg.message,
              sentTime: msg.sentTime,
              sender: msg.sender,
              direction: msg.direction,
              position: msg.position,
            }}
            className="text-black"
          >
            {renderMessageContent(msg)}
          </Message>
        ))}
      </div>
    </div>
  );
};
