const ERoutes = {
  FREE_TRIAL: '/web-development-trial',
  FULL_STACK: '/full-stack-developer-track',
  BACKEND: '/backend-developer-track',
  FELLOWSHIP: '/software-development-fellowship-program',
  SDE2: '/software-development-fellowship-program-plus',
  QA_AUTOMATION_TESTING: '/qa-automation-testing',
  DATA_SCIENCE: '/ai-data-analytics',
  SYSTEM_DESIGN: '/fellowship-program-system-design',
  PLACEMENTS: '/placements',
  SUCCESS_STORIES: '/testimonials',
  RECRUIT: '/recruit',
  ONBOARD: '/onboard',
  BLOG: 'https://www.crio.do/blog/',
  PROJECTS: '/projects',
  IBD: '/ibelieveindoing',
  CWOD: '/crio-winter-of-doing',
  ABOUT: '/about-us',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms',
  EMAIL: 'mailto:ping@criodo.com',
  CUSTOMER_QUERIES_EMAIL: 'mailto:support@criodo.com',
  GENERAL_ENQUIRIES_EMAIL: 'mailto:ping@criodo.com',
  ROI: '/roi-calculator',
  MASTERCLASS: '/masterclass',
  HOME_PAGE: '/',
};

const EPathname = {
  SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM:
    '/software-development-fellowship-program/',
  SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM_PPC_PAGE:
    '/programs/software-development-fellowship-program/',
  ACCELERATE: '/accelerate/',
  SYSTEM_DESIGN: '/fellowship-program-system-design/',
  DATA_ANALYTICS: '/ai-data-analytics/',
  DATA_ANALYTICS_PPC_PAGE: '/programs/ai-data-analytics/',
  QA_AUTOMATION_TESTING: '/qa-automation-testing/',
  QA_PPC_PAGE: '/programs/qa-automation-testing/',
  CAMPAIGN: '/campaign/',
  ON_DEMAND_SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM:
    '/on-demand/software-development-fellowship-program/',
  ON_DEMAND_QA_AUTOMATION_TESTING: '/on-demand/qa-automation-testing/',
  ON_DEMAND_AI_DATA_ANALYTICS: '/on-demand/ai-data-analytics/',
};

const EPpcRoutes = {
  FULL_STACK_PPC_ADS_VL: 'full-stack-developer-track/ppc-ads-vl',
  FULL_STACK_PPC_ADS: 'full-stack-developer-track/ppc-ads',
};

const ESocialLinks = {
  LINKEDIN: 'https://www.linkedin.com/company/criodo/?utm_source=crio-website',
  YOUTUBE:
    'https://www.youtube.com/channel/UCE1_bZxswCCARhhVeSQLuew/?utm_source=crio-website',
  INSTAGRAM: 'https://www.instagram.com/crio.do/?utm_source=crio-website',
  TWITTER: 'https://twitter.com/crio_do/?utm_source=crio-website',
  FACEBOOK: 'https://www.facebook.com/criodo?utm_source=crio-website',
};

module.exports = {
  ERoutes,
  EPpcRoutes,
  ESocialLinks,
  EPathname,
};
