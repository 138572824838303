import { useCustomToast } from '@components/extra/useCustomToast';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import Layout from '@components/v5/layouts/Layout';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { LEAD_GEN_MASTERCLASS_POST_REGISTRATION } from '@src/constants/LeadGenerationConstants';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import Cookies from 'js-cookie';
import React, { useContext } from 'react';

export default function RequestCallback() {
  const state = useContext(GlobalStateContext);

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        Thanks! We will give you a call or email you between 9 AM to 9 PM
      </p>
    </div>,
  );

  function onSubmit(email, phone) {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: 'CALL_ME_POPUP',
    });

    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED,
      email,
    );

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    if (Cookies.get('isPhoneVerified') === 'true') triggerSuccessToast();
  }
  return (
    <Layout className="container pt-16">
      <div className="flex flex-col items-center justify-center gap-x-12 gap-y-[24px] rounded-2xl bg-v5-neutral-200 py-6 px-7 md:flex-row md:items-stretch md:p-16">
        <div className="text-center md:text-left">
          <div className="leading-1 font-manrope text-[24px] font-bold text-v5-green-700 md:text-left md:text-[40px]">
            Want to know more about{' '}
            <span className="text-v5-green-200">Crio.Do ?</span>
          </div>
          <div className="leading-1 font-rubik text-[12px] text-v5-neutral-500 md:text-2xl">
            Connect with our team
          </div>
        </div>

        <LeadGenerationButton
          wrapperClassName={'inline-block cursor-pointer'}
          buttonComponent={
            <div className="flex cursor-pointer items-center justify-center rounded-[10px] border-2 border-v5-neutral-600 py-3 px-10 text-center md:px-5 lg:px-10 lg:py-4">
              Request a Call Back
            </div>
          }
          isOnePageForm
          formHeading="Request Callback"
          pageOneButtonText="Call me back"
          buttonLocation={LEAD_GEN_MASTERCLASS_POST_REGISTRATION}
          onSubmitPageOne={onSubmit}
          clickEvent={() => {
            GTM.track(gtmEvents.CALLME_OPENED);
            GTM.leadSquaredEvent(
              ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED,
              state.email,
            );
          }}
        ></LeadGenerationButton>
      </div>
    </Layout>
  );
}
