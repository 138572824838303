import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faGit,
  faGithub,
  faHtml5,
  faInstagram,
  faJava,
  faJs,
  faLinkedin,
  faLinux,
  faNodeJs,
  faReact,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faGift } from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngleDown,
  faBook as faBookLight,
  faCalendar,
  faClock as faClockLight,
  faCloudUpload,
  faCopy,
  faDownload,
  faGem,
  faGraduationCap,
  faLongArrowLeft,
  faLongArrowRight,
  faProjectDiagram,
  faQuestion,
  faRedoAlt,
  faStar as faStarLight,
  faStars,
  faTimes as faTimesLight,
  faTrophy,
  faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faBell,
  faBook as faBookSolid,
  faBriefcase,
  faWallet,
  faWindow,
  faUserTie as faUserTieSolid,
  faCalendarAlt,
  faChartArea,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClock as faClockSolid,
  faCommentAlt,
  faDownload as faDownloadSolid,
  faExclamationCircle,
  faFileDownload,
  faFlag,
  faGraduationCap as faGraduationCapSolid,
  faBriefcase as faBriefcaseSolid,
  faHome,
  faImages,
  faMoneyBillWave,
  faMotorcycle,
  faPaperPlane,
  faPhoneAlt,
  faPlaneDeparture,
  faPlay,
  faRocket,
  faIndianRupeeSign,
  faServer,
  faShoppingCart,
  faStar as faStarSolid,
  faTag,
  faTerminal,
  faTimesCircle,
  faTimes as faTimesSolid,
  faUser,
  faUsers,
  faUtensilsAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBars as faBarsRegular,
  faBook,
  faChevronCircleUp,
  faClock,
  faLongArrowRight as faLongArrowRightRegular,
  faPlus,
  faSearch,
  faIndianRupeeSign as faIndianRupeeSignRegular,
  faSignal,
  faTimes,
  faVideo,
  faUserTie as faUserTieRegular,
  faScreenUsers,
  faClipboardListCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from '@reach/router';

import React, { useContext, useEffect } from 'react';
import packageJson from '../../package.json';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { useAuth } from '../firebase/useAuth';

import { Toaster } from 'react-hot-toast';
import { fetchUserDetails } from '@src/api/index';
import { GTM } from '@src/analytics/gtm';
import { Helmet } from 'react-helmet';
import { config } from '@fortawesome/fontawesome-svg-core';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@components/ErrorBoundary/index';
import * as Sentry from '@sentry/gatsby';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { useUserLocation } from '@src/hooks/useUserLocation';

import schema from '../seo/jsonld/schema.json';
import reviews from '../seo/jsonld/reviews.json';
import useProvisionalEnrollment from '@src/hooks/useProvisionalEnrollment';

global.appVersion = packageJson.version;

config.autoAddCss = false;

library.add(
  faIndianRupeeSign,
  faIndianRupeeSignRegular,
  faPlus,
  faChevronRight,
  faBarsRegular,
  faLongArrowRightRegular,
  faLongArrowRight,
  faChevronCircleUp,
  faArrowLeft,
  faArrowRight,
  faLinkedin,
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faStarLight,
  faArrowUp,
  faArrowDown,
  faTag,
  faAngleDown,
  faTimes,
  faTimesLight,
  faTimesSolid,
  faTimesCircle,
  faBars,
  faGift,
  faExclamationCircle,
  faPlay,
  faCopy,
  faDownload,
  faClock,
  faClockLight,
  faClockSolid,
  faSearch,
  faSignal,
  faChevronLeft,
  faCommentAlt,
  faPhoneAlt,
  faGraduationCapSolid,
  faUsers,
  faBook,
  faBookLight,
  faCalendar,
  faGem,
  faGraduationCap,
  faLongArrowLeft,
  faProjectDiagram,
  faQuestion,
  faRedoAlt,
  faUserTie,
  faGithub,
  faHtml5,
  faJava,
  faJs,
  faNodeJs,
  faReact,
  faBriefcase,
  faCalendarAlt,
  faChartArea,
  faFlag,
  faHome,
  faImages,
  faMoneyBillWave,
  faMotorcycle,
  faPlaneDeparture,
  faRocket,
  faServer,
  faShoppingCart,
  faStarSolid,
  faTerminal,
  faUser,
  faTrophy,
  faUtensilsAlt,
  faCheck,
  faBookSolid,
  faCloudUpload,
  faStars,
  faGit,
  faLinux,
  faFileDownload,
  faBell,
  faPaperPlane,
  faDownloadSolid,
  faAngleLeft,
  faAngleRight,
  faBriefcaseSolid,
  faVideo,
  faUserTieRegular,
  faScreenUsers,
  faClipboardListCheck,
  faWallet,
  faWindow,
  faUserTieSolid,
);

export default function Root({ children }) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const auth = useAuth();
  const disableOnPaths = ['/redeem/', '/giveaway/', '/error/404'];
  const location = useLocation();
  const pathName = location?.pathname;

  useUserLocation();

  useEffect(() => {
    window.dataLayer.push({
      [gtmEvents.WEB_VAR_EMAIL]: state?.email,
    });
  }, []);

  const { isProvisionalEnrollment } = useProvisionalEnrollment();

  useEffect(() => {
    if (isProvisionalEnrollment) {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.PROVISIONAL_CHECKOUT_PAGE_VIEWED,
        state.email,
      );
    }

    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.WEBSITE_VISITED,
      state?.email,
      pathName,
    );

    setTimeout(() => {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.TIME_SPENT_ON_WEBSITE_MORE_THAN_TWO_MINUTES,
        state?.email,
        pathName,
      );
    }, 120000);

    setTimeout(() => {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.TIME_SPENT_ON_WEBSITE_MORE_THAN_FIVE_MINUTES,
        state?.email,
        pathName,
      );
    }, 300000);
  }, []);

  const fetchUserId = async (user) => {
    try {
      const token = await user.getIdToken();
      try {
        const userDetails = await fetchUserDetails(token);
        GTM.ga4Login(userDetails.userId);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addGclidUserAttribute = (gclid) => {
    GTM.addUserAttributes({ gclid: gclid });
  };

  useEffect(() => {
    if (auth?.user) {
      fetchUserId(auth.user);
    }
  }, [auth?.user]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const gclid = params?.gclid;

    //adwords
    if (gclid) addGclidUserAttribute(gclid);
  }, []);

  useEffect(() => {
    // fetchCountry();

    if (typeof window !== 'undefined') {
      let temp =
        window.location.search.length > 0 ? window.location.search : '';

      temp = temp.split('?').join('');

      if (!disableOnPaths.includes(location.pathname)) {
        if (temp.length > 0) {
          dispatch({
            type: 'SET_UTM',
            payload: temp,
          });
        }
      }
    }
  }, []);
  const theme = {
    background: '#f5f8fb',
    fontFamily:
      'Rubik,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    headerBgColor: '#7bba8c',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#fff',
    botFontColor: '#4a4a4a',
    userBubbleColor: '#7bba8c',
    userFontColor: '#fff',
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(error, errorInfo) => {
        if (process.env.NODE_ENV === 'production') {
          Sentry.configureScope((scope) => {
            scope.setTag('type', 'crash');
            if (state.email) {
              Sentry.setUser({ email: state.email });
            }
            Object.keys(errorInfo).forEach((key) => {
              scope.setExtra(key, errorInfo[key]);
            });
          });
          Sentry.captureException(error);
        }
      }}
    >
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          rel="preload"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600;700;800&family=Rubik:wght@300;400;600;700;800;900&display=swap"
        />
        <link
          rel="preload"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&display=swap"
        ></link>
        <link
          rel="preload"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
          href="https://fonts.googleapis.com/css2?family=Dosis:wght@500;700;800&family=Lato:wght@400;700;900&display=swap"
        ></link>
        <meta
          name="facebook-domain-verification"
          content="w8pizttw6xjg1wuzn8idhzawa1hupf"
        />
        <script type="application/ld+json">{JSON.stringify(reviews)}</script>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          top: 80,
        }}
        toastOptions={{
          // Define default options
          duration: 5000,
        }}
      />

      {children}
    </ErrorBoundary>
  );
}
