import { cn } from '@/lib/utils';
import { getImage } from 'gatsby-plugin-image';
import { navigate } from '@reach/router';

export const getCurrentMasterclassEventDetail = (
  masterClassEvents,
  pathName,
) => {
  return masterClassEvents.find((event) =>
    pathName.includes(event?.attributes?.eventSlug),
  )?.attributes;
};

export const filterImagesByEventSlug = (images, eventSlug) => {
  return images.filter((image) => image.relativePath.includes(eventSlug));
};

export const filterMasterclassImages = (images) => {
  return images.filter((image) =>
    image.relativePath.includes('masterclass-details'),
  );
};

export const findBannerImage = (images, isMobile) => {
  const searchTerm = isMobile ? 'banner_image_mobile' : 'banner_image';

  // Sort images by name to prioritize 'banner_image_mobile' over 'banner_image'
  const sortedImages = images.sort((a, b) => a.name.localeCompare(b.name));

  return sortedImages.find((image) => {
    const imageName = image.name.toLowerCase();
    return (
      imageName.includes(searchTerm.toLowerCase()) &&
      !imageName.includes('large')
    );
  });
};

export const findSessionHostImage = (images) => {
  return images.find((image) =>
    image.name.toLowerCase().includes('session_host'),
  );
};

export const findEventCardSessionImage = (images) => {
  return images.find((image) =>
    image.name.toLowerCase().includes('event_card'),
  );
};

export const findAboutCrioImg = (images) => {
  return images.find(
    (image) =>
      image.name.toLowerCase().includes('about_crio') &&
      !image.name.includes('large'),
  );
};

export const formatDate = (date) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);

  // Replace slashes with dashes
  return formattedDate.replace(/\//g, '-');
};

export const formatTime = (date) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const getDynamicSeatCount = (seatCount, createdAt, sessionStartDateTime) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);

  // Calculate the total duration from createdAt to sessionStartDateTime
  const totalDurationMs = sessionStartDate - creationDate; // Total time from creation to session start
  const totalDurationDays = totalDurationMs / (1000 * 60 * 60 * 24); // Convert to days

  // Dynamic thresholds
  const thresholds = [
    { percentage: 20, seatFactor: 0.4, targetDays: totalDurationDays * 0.2 },
    { percentage: 60, seatFactor: 0.8, targetDays: totalDurationDays * 0.6 },
    { percentage: 100, seatFactor: 1.0, targetDays: totalDurationDays },
  ];

  const today = new Date();

  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    if (today < targetDate) {
      // Gradual seats for current threshold
      const daysPassed = Math.floor(
        (today - creationDate) / (1000 * 60 * 60 * 24),
      );
      const dailyIncrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );
      const gradualSeats = Math.min(
        sessionSeats * threshold.seatFactor,
        dailyIncrement * daysPassed,
      );

      return Math.floor(gradualSeats);
    }
  }

  return sessionSeats; // If past the final threshold
};

const getDynamicSeatCountDecrease = (
  seatCount,
  createdAt,
  sessionStartDateTime,
) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);

  // Define key time points:
  // 1. Midnight of the session date
  // 2. 4 hours before the session
  // 3. 1 hour after the session
  const midnightOfSessionDate = new Date(sessionStartDate);
  midnightOfSessionDate.setHours(0, 0, 0, 0);

  const fourHoursBeforeSession = new Date(sessionStartDate);
  fourHoursBeforeSession.setHours(fourHoursBeforeSession.getHours() - 4);

  const oneHourAfterSession = new Date(sessionStartDate);
  oneHourAfterSession.setHours(oneHourAfterSession.getHours() + 1);

  const today = new Date();

  // Seeded random generator
  const seededRandom = (seed) => {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  };

  // Generate a consistent "random" number based on the session's unique identifiers
  const sessionSeed = creationDate.getTime() + sessionStartDate.getTime(); // Combine creation and session times for a unique seed

  const randomValue = seededRandom(sessionSeed);

  // Logic for different periods
  if (today >= midnightOfSessionDate && today < fourHoursBeforeSession) {
    // Between 12:00 AM and 4 hours before the session
    const minSeats = 50; // Minimum seats
    const maxSeats = 60; // Maximum seats
    return Math.ceil(
      Math.min(sessionSeats, minSeats + randomValue * (maxSeats - minSeats)),
    );
  }

  if (today >= fourHoursBeforeSession && today < oneHourAfterSession) {
    // Between 4 hours before session and 1 hour after session
    const minSeats = 20; // Minimum seats during this period
    const maxSeats = 40; // Maximum seats during this period
    return Math.ceil(minSeats + randomValue * (maxSeats - minSeats));
  }

  if (today >= oneHourAfterSession) {
    // After 1 hour past the session start
    return 0;
  }

  // Default behavior for days before the session date
  const totalDurationMs = sessionStartDate - creationDate; // Total time from creation to session start
  const totalDurationDays = totalDurationMs / (1000 * 60 * 60 * 24); // Convert to days

  // Dynamic thresholds for gradual reduction
  const thresholds = [
    { percentage: 20, seatFactor: 0.4, targetDays: totalDurationDays * 0.2 },
    { percentage: 60, seatFactor: 0.8, targetDays: totalDurationDays * 0.6 },
    { percentage: 100, seatFactor: 1.0, targetDays: totalDurationDays },
  ];

  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    if (today < targetDate) {
      // Gradual seat reduction for the current threshold
      const daysPassed = Math.floor(
        (today - creationDate) / (1000 * 60 * 60 * 24),
      );
      const dailyDecrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );
      const gradualSeats = Math.max(
        sessionSeats - dailyDecrement * daysPassed,
        sessionSeats * (1 - threshold.seatFactor),
      );

      return Math.ceil(gradualSeats);
    }
  }

  return 0; // If past the final threshold, all seats are reduced
};

export const getSessionDetails = (eventDetails) => {
  const sessionStartDateTime =
    eventDetails?.sessionDetails?.sessionStartDateTime;
  const sessionDate = sessionStartDateTime
    ? formatDate(new Date(sessionStartDateTime))
    : null;
  const sessionTime = sessionStartDateTime
    ? formatTime(new Date(sessionStartDateTime))
    : null;
  const createdAt = eventDetails?.createdAt;

  const isSeatTypeHyped =
    eventDetails?.sessionDetails?.sessionSeatType === 'Registered';
  return {
    sessionName: eventDetails?.sessionDetails?.sessionName,
    sessionDate,
    sessionTime,
    createdAt,
    sessionStartDateTime,
    sessionHostName: eventDetails?.sessionDetails?.sessionHostName,
    sessionHostDesignation:
      eventDetails?.sessionDetails?.sessionHostDesignation,
    isSeatTypeHyped,
    isPastEvent: eventDetails?.everWebinarDetails?.isPastEvent,
    sessionSlug: eventDetails?.eventSlug,
    sessionProgram: eventDetails?.program,
    sessionDescription: eventDetails?.sessionDetails?.aboutSession,
    sessionSeats: isSeatTypeHyped
      ? getDynamicSeatCount(
          eventDetails?.sessionDetails?.sessionSeats,
          eventDetails?.createdAt,
          eventDetails?.sessionDetails?.sessionStartDateTime,
        )
      : getDynamicSeatCountDecrease(
          eventDetails?.sessionDetails?.sessionSeats,
          eventDetails?.createdAt,
          eventDetails?.sessionDetails?.sessionStartDateTime,
        ),
  };
};

export const getShareInfo = (eventDetails) => {
  return eventDetails?.shareInformation;
};

export const getIsPastEvent = (sessionDate) => {
  const eventDateTime = new Date(sessionDate);
  const currentDateTime = new Date();
  return eventDateTime < currentDateTime;
};

export const getSessionCardImg = (allMasterclassImg, eventSlug) => {
  return getImage(
    allMasterclassImg.find(
      (node) =>
        node.relativePath.includes(eventSlug) &&
        node.name.toLowerCase().includes('event_card') &&
        !node.name.includes('large'),
    ),
  );
};

export const sanitizeEventDetails = (eventDetails, allMasterclassImg) => {
  const {
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionStartDateTime,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
    isPastEvent,
    createdAt,
    sessionProgram,
    sessionSlug,
    sessionDescription,
  } = getSessionDetails(eventDetails);
  const shareInfo = getShareInfo(eventDetails);
  const sessionCardImg = getSessionCardImg(
    allMasterclassImg,
    eventDetails?.eventSlug,
  );

  return {
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionDescription,
    sessionSlug,
    sessionStartDateTime,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
    createdAt,
    shareInfo,
    sessionProgram,
    isPastEvent,
    sessionCardImg,
  };
};

export const getClipedHref = (href) => {
  return href.length > 30 ? `${href.slice(0, 30)}...` : href;
};

//Calculate if there is less than 15 mins left for the session to start
export const isSessionNearby = (sessionStartDateTime) => {
  if (!sessionStartDateTime) return false;

  const sessionStart = new Date(sessionStartDateTime);
  const currentTime = new Date();

  const sessionStartUTC = Date.UTC(
    sessionStart.getUTCFullYear(),
    sessionStart.getUTCMonth(),
    sessionStart.getUTCDate(),
    sessionStart.getUTCHours(),
    sessionStart.getUTCMinutes(),
  );

  const currentTimeUTC = Date.UTC(
    currentTime.getUTCFullYear(),
    currentTime.getUTCMonth(),
    currentTime.getUTCDate(),
    currentTime.getUTCHours(),
    currentTime.getUTCMinutes(),
  );

  const timeDifference = sessionStartUTC - currentTimeUTC;
  return timeDifference <= 15 * 60 * 1000 && timeDifference > 0;
};

const padZero = (num) => (num < 10 ? `0${num}` : num);

const formatDateForCal = (date) => {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); // Months are zero-based
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}${month}${day}T${hours}${minutes}${seconds}`;
};

// Convert date to IST
const convertToIST = (date) => {
  const utcDate = new Date(date);
  const istDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }),
  );
  return istDate;
};

export const getFormattedDate = (date) => {
  const startDate = convertToIST(date);
  const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours

  const formattedStartDate = formatDateForCal(startDate);
  const formattedEndDate = formatDateForCal(endDate);

  return `${formattedStartDate}/${formattedEndDate}`;
};

export const getShareContent = (sessionName, whatsappGroupLink) => {
  const text =
    'Here is your link to join the Whatsapp group for ' +
    sessionName +
    '\n' +
    whatsappGroupLink;
  const url = `https://wa.me/?text=${encodeURIComponent(text)}`;
  return url;
};

export const calculateTimeRemaining = (startTime) => {
  const startTimeIST = convertToIST(startTime);
  const nowIST = convertToIST(new Date());

  const distance = startTimeIST.getTime() - nowIST.getTime();

  if (distance < 0) {
    // Event has already started
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  return {
    days: Math.floor(distance / (1000 * 60 * 60 * 24)),
    hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((distance % (1000 * 60)) / 1000),
  };
};

// Helper function to get button classes
export const getButtonClasses = (isSessionAvailable) =>
  cn(
    `natural sm flex h-10 w-[150px] items-center whitespace-nowrap rounded-[4px] 
     border-2 border-v5-yellow-200 bg-v5-yellow-200 font-manrope text-[12px] 
     font-bold capitalize leading-[7px] text-black hover:bg-v5-yellow-100 
     md:h-[44px] md:w-64 md:rounded-[10px] md:text-[18px]`,
    { 'cursor-not-allowed opacity-50': !isSessionAvailable },
  );

// Helper function to handle redirection to EverWebinar
export const redirectToEverWebinar = (state, eventSlug) => {
  // Check if the user is registered for the event
  const isRegistered =
    state.thankYouUrl &&
    state.thankYouUrl.length >= 0 &&
    state.thankYouUrl.some((item) => item?.[eventSlug]);
  if (isRegistered) {
    const eventSlugValue = state.thankYouUrl.find(
      (item) => item[eventSlug] != null,
    )?.[eventSlug];
    window.open(eventSlugValue);
  }
};

// Helper function to check user registration
export const checkUserRegistration = (state, eventSlug) => {
  return (
    state.thankYouUrl &&
    state.thankYouUrl.length >= 0 &&
    state.thankYouUrl.some((item) => item?.[eventSlug])
  );
};

// Helper function to redirect to registration page if user is not registered
export const redirectToRegistration = (isUserRegistered, eventSlug) => {
  if (!isUserRegistered) {
    navigate('/masterclass/register/' + eventSlug, { replace: true });
  }
};

export const openLinkInNewTab = (url) => {
  window.open(url, '_blank');
};

/** Upcoming Events condition:
 * 1. Event is not marked as past event on strapi
 * 2. Current time is less than 2 hours from the session start time
 */
export const getUpcomingEvents = (events) => {
  const currentTime = new Date();
  return events
    .filter((event) => {
      const sessionStartDateTime = new Date(event.sessionStartDateTime);
      const sessionEndDateTime = new Date(
        sessionStartDateTime.getTime() + 2 * 60 * 60 * 1000,
      );
      return !event.isPastEvent && currentTime <= sessionEndDateTime;
    })
    .sort(
      (a, b) =>
        new Date(a.sessionStartDateTime) - new Date(b.sessionStartDateTime),
    );
};
